/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { setNewStateLeads } from '../../actions/leadActions'
import { Intl } from '../../utils'

const ContentHeader = ({
  isOpenAddQuiz,
  openAddQuiz,
  pathname,
  isOpenLeads,
  openLeads,
  leadState,
  csvLink,
  selectedLeads,
  page,
  updateLeads,
  quizFilterRedux,
  importQuizJSON,
  quizzesLength,
  importIsNotAvailable,
  onOpenAlertModal,
}) => {
  const [link, setLink] = useState(csvLink.defaultLink)
  const [isOpen, setIsOpen] = useState(false)

  const { leadFilter } = csvLink

  const draftQuiz = localStorage.draftJson && JSON.parse(localStorage.draftJson)

  const handleClickImport = (e) => {
    if (importIsNotAvailable) {
      e.preventDefault()
      onOpenAlertModal()
    }
  }

  // Формирование ссылки на скачивание csv отчета для заявок
  useEffect(() => {
    let resultLink = csvLink.defaultLink
    if (csvLink.quizFilter) {
      resultLink = `${resultLink}&quiz_hash=${csvLink.quizFilter}`
    }
    if (csvLink.leadFilter) {
      resultLink = `${resultLink}&state=${csvLink.leadFilter === 'all' ? '' : csvLink.leadFilter}`
    }
    setLink(resultLink)
  }, [csvLink.quizFilter, csvLink.leadFilter])

  return (
    <div className={`content-header row ${pathname === '/leads' ? 'leads-content-row-header' : ''}`}>
      <div className="content-header-left col-md-4 col-12 mb-2" />
      <div className="content-header-right col-md-8 col-12">
        {((pathname === '/' && quizzesLength) || (pathname === '/' && draftQuiz)) && (
          <Dropdown
            isOpen={isOpenAddQuiz}
            toggle={openAddQuiz}
            className="btn-group float-md-right top-buttons"
            role="group"
            aria-label="Button group with nested dropdown"
          >
            <DropdownToggle
              className="btn btn-create round dropdown-toggle dropdown-menu-right box-shadow-2 px-2 top-buttons"
              id="btnGroupDrop1"
              type="button"
              data-toggle="dropdown"
              aria-haspopup
              aria-expanded="false"
            >
              <i className="ft-plus icon-left" />
              <FormattedMessage id="contentHeader.createQuizz" defaultMessage="СОЗДАТЬ КВИЗ" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <Link to="/empty" style={{ color: '#2a2e30' }}>
                <DropdownItem className="dropdown-item">
                  <i className="ft-square" />
                  <FormattedMessage id="contentHeader.fromScratch" defaultMessage="С нуля" />
                </DropdownItem>
              </Link>
              <Link to="/templates" style={{ color: '#2a2e30' }}>
                <DropdownItem className="dropdown-item">
                  <i className="ft-image" />
                  <FormattedMessage id="contentHeader.basedTemplate" defaultMessage="На основе шаблона" />
                </DropdownItem>
              </Link>
              <div className="dropdown-item" onClick={handleClickImport}>
                <i className="fa fa-upload" aria-hidden="true" />
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="input-file"
                  accept="application/JSON"
                  onChange={event => importQuizJSON(event)}
                />
                <label htmlFor="file">
                  <FormattedMessage id="contentHeader.import" defaultMessage="Импортировать" />
                </label>
              </div>
            </DropdownMenu>
          </Dropdown>
        )}
        {(pathname.slice(0, 6) === '/leads') && (
          <div className="leads-content-header">
            <a href={link} className="csv" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
              <Button
                type="button"
                className="btn round px-2 float-md-right btn-secondary top-buttons quiz__button--actions"
              >
                Скачать в CSV
              </Button>
            </a>
            <Dropdown
              isOpen={isOpenLeads}
              toggle={openLeads}
              className="btn-group float-md-right top-buttons drop-button"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <DropdownToggle
                className="btn btn-success round dropdown-toggle dropdown-menu-right px-2"
                id="btnGroupDrop1"
                type="button"
                data-toggle="dropdown"
                aria-haspopup
                aria-expanded="false"
              >
                {' '}
                {leadState}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <Link to="/leads" style={{ color: '#2a2e30' }}>
                  <DropdownItem className="dropdown-item">
                    {Intl.getMessage('contentHeader.allButArchived')}
                  </DropdownItem>
                </Link>
                <Link to="/leads/new" style={{ color: '#2a2e30' }}>
                  <DropdownItem className="dropdown-item">
                    {Intl.getMessage('contentHeader.onlyNew')}
                  </DropdownItem>
                </Link>
                <Link to="/leads/seen" style={{ color: '#2a2e30' }}>
                  <DropdownItem className="dropdown-item">
                    {Intl.getMessage('contentHeader.onlyViewed')}
                  </DropdownItem>
                </Link>
                <Link to="/leads/done" style={{ color: '#2a2e30' }}>
                  <DropdownItem className="dropdown-item">
                    {Intl.getMessage('contentHeader.onlyProcessed')}
                  </DropdownItem>
                </Link>
                <Link to="/leads/unfinished" style={{ color: '#2a2e30' }}>
                  <DropdownItem className="dropdown-item">
                    {Intl.getMessage('contentHeader.onlyIncomplete')}
                  </DropdownItem>
                </Link>
                <Link to="/leads/archived" style={{ color: '#2a2e30' }}>
                  <DropdownItem className="dropdown-item">
                    {Intl.getMessage('contentHeader.onlyArchival')}
                  </DropdownItem>
                </Link>
              </DropdownMenu>
            </Dropdown>
            {selectedLeads.length > 0 && (
            <Dropdown
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              className="btn-group float-md-right top-buttons drop-status"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <DropdownToggle
                className="btn btn-success round dropdown-toggle dropdown-menu-right px-2"
                id="btnGroupDrop1"
                type="button"
                data-toggle="dropdown"
                aria-haspopup
                aria-expanded="false"
              >
                Изменить статус выбранных
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => updateLeads({
                    page, leadFilter, quizFilter: quizFilterRedux, type: 'new', selectedLeads,
                  })}
                >
                  Новый
                </DropdownItem>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => updateLeads({
                    page, leadFilter, quizFilter: quizFilterRedux, type: 'seen', selectedLeads,
                  })}
                >
                  Просмотренный
                </DropdownItem>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => updateLeads({
                    page, leadFilter, quizFilter: quizFilterRedux, type: 'done', selectedLeads,
                  })}
                >
                  Обработанный
                </DropdownItem>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => updateLeads({
                    page, leadFilter, quizFilter: quizFilterRedux, type: 'archived', selectedLeads,
                  })}
                >
                  Архив
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  csvLink: state.csvLinkReducer.csvLink,
  selectedLeads: state.leadReducer.selectedLeads,
  page: state.leadReducer.page,
  quizFilterRedux: state.leadReducer.quizFilter,
})

const mapDispatchToProps = {
  updateLeads: setNewStateLeads,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader)
